#mc_embed_signup {
  position: relative;
  width: 100%;
  max-width: 293px;
  margin: rem($base-gutter) auto;

  input {
    height: 44px;
  }

  .button {
    position: absolute;
    top: 0;
    right: 0;
    color: color('orange');
    background-color: white;
  }
}

// Default page element styling.
//
body {
  margin: 0;
  font-size: $base-font-size;
  font-family: $roboto-light;
  letter-spacing: 1px;
  line-height: $base-line-height;
  color: $base-font-color;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  width: 100%;
  height: 1px;
  margin-top: rem($base-gutter);
  margin-bottom: rem($base-gutter);
  background-color: color('yellow');
  border: 0;

  // Medium Desktops
  @include media(md) {
    margin-top: rem(double($base-gutter));
    margin-bottom: rem(double($base-gutter));
  }
}

a {
  text-decoration: none;
  outline-style: none;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: top;

    + li {
      margin-left: rem(10px);
    }
  }

  button {
    width: 15px;
    height: 15px;
    text-indent: 9999px;
    background-color: white;
    border: 0;
    border-radius: 50%;
    appearance: none;
    outline-style: none;
  }

  .slick-active {
    button {

    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: z('demigod');
  padding: 0;
  color: color('green');
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  appearance: none;
  @include fluid-type($base-min-width, $base-max-width, 24px, 34px);
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

// Logo
//
// Use this class for the logo component.
//
// Markup:
// <a class="logo">Logo</a>
//
.logo {
  display: block;

  .header & {
    position: absolute;
    bottom: neg(81px);
    left: 0;
    right: 0;
    z-index: z('demigod');
    width: 225px;

    // Medium Max
    @include media-max(md) {
      margin-left: auto;
      margin-right: auto;
    }

    // Medium Desktops
    @include media(md) {
      bottom: neg(51px);
      left: 15px;
      width: 230px;
    }

    // Large Desktops
    @include media(lg) {
      bottom: neg(61px);
      width: 270px;
    }
  }
}

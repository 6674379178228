// Default form element styling.
//
input,
select,
textarea {
  width: 100%;
  padding: rem(10px);
  color: $base-font-color;
  border: 1px solid color('grey', 'medium');
  border-radius: 0;
  appearance: none;
  outline: none;
}

input[type="submit"] {
  display: inline-block;
  width: auto;
  padding: rem(5px) rem(20px);
  font-family: $paytone-one;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  background-color: color('orange');
  border: 0;

  @include link-states() {
    background-color: color('yellow');
  }
}

::-webkit-input-placeholder {
  font-family: $paytone-one;
  color: color('orange');
  text-transform: uppercase;
}

::-moz-placeholder {
  font-family: $paytone-one;
  color: color('orange');
  text-transform: uppercase;
}

:-ms-input-placeholder {
  font-family: $paytone-one;
  color: color('orange');
  text-transform: uppercase;
}

:-moz-placeholder {
  font-family: $paytone-one;
  color: color('orange');
  text-transform: uppercase;
}

// Contact
.property__contact {
  background-color: color('blue');
  padding: 30px;
  color: black;
  position: relative;
  height: 100%;

  p,
  div,
  a,
  strong,
  label {
    text-decoration: none;
    position: relative;
    z-index: 10;
    color: white;
  }

  .gfield_label {
    display: block !important;
    color: white !important;
  }

  input,
  textarea {
    color: black !important;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /// Insert Background Image here
    //background-image: url("/app/themes/sage/resources/assets/images/leaf-white.svg");
    background-position: center -120px;
    background-repeat: no-repeat;
    background-size: 250%;
    opacity: 0.4;
  }

  // 1023 <=
  @media (max-width: 1023px) {
    margin-top: 0.25rem;
  }

  // 1024
  @media (min-width: 1024px) {
    width: calc(33.3333333333% - 15px);
  }

  a[href^="tel"] {
    font-size: 34px;
  }

  a[href^="mailto"] {
    color: white !important;
    text-decoration: underline;
  }

  .gform_wrapper {
    margin-top: 30px;
  }
}

.tac {
  padding-top: 0px;

  height: auto;
  max-height: auto;

  p {
    margin: 0px;
  }
}

.section-home-blurb {
  background-color: color('blue');
  padding-top: 45px;
}

// Property container
//
.properties-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

// Property
//

.property {
  padding-bottom: 60px;

  .properties & {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      margin-bottom: 0px;
      padding-left: 15px;
      padding-right: 15px;

      width: 50%;
    }

    // 1024
    @media (min-width: 1030px) {
      margin-bottom: 0px;
      padding-left: 15px;
      padding-right: 15px;

      width: 33.3333%;
    }
  }
}

// Pagination
//
.property__pagination {
  .c-post__nav {
    display: flex;
    justify-content: center;

    .c-post__nav-list {
      list-style-type: none;
      display: flex;

      li + li {
        margin-left: 15px;
      }
    }
  }
}

// Adjacent Properties
//
.property + .property {
  // 1023 <=
  @media (max-width: 1023px) {
    //
  }
}

.hover_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 1s;
  top: 0;
  background-color: rgba(#d92027, 0.5);
  transform: scale(0);
}

// Thumb
.property__thumb {
  width: 100%;
  padding: 175px 0;
  background-position: center !important;
  background-size: cover !important;
  position: relative;
  overflow: hidden;

  &:hover {
    .hover_content {
      top: 0;
      transform: scale(1);
    }
  }
}

.property__title {
  text-align: center;
}

//// Short Code Module
.property-slider {
  padding-bottom: 90px;
  background-color: color('blue');
  text-align: center;

  @media (max-width: 1023px) {
    padding-bottom: 45px;
  }

  .btn {
    margin-top: 45px;
  }

  //  Property Slider
  .property-slide {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    // Thumbnaill
    .property__thumb {
      background-position: center !important;
      background-size: cover !important;
    }

    p {
      margin: 0px;
    }
  }

  .slick-dots {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    padding: 0px;

    button {
      background-color: color('blue');
    }

    .slick-active {
      button {
        background-color: color('grey');
      }
    }
  }
}

// Meta
//
.meta_container {
  padding: 30px;
  background-color: color('grey');
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-flow: column wrap;
  min-height: 300px;

  h6 {
    margin: 0px;

    font-size: 26px;
  }

  .btn {
    margin: 0px;
  }

  .page-template-template-properties & {
    background-color: color('blue');
  }

  a {
    text-decoration: none;
  }
}

.property__meta {
  margin-bottom: 0px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  span {
    color: color('green');
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  span:nth-child(2) {
    &::before {
      content: "\007C";
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  span:nth-child(3) {
    &::before {
      content: "\007C";
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .property__meta span:nth-child(4)::before {
    content: "\007C";
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 0;
    padding-right: 0;
  }
}

// Content
//
.property__content {
  margin-top: 30px;

  // 1024
  @media (min-width: 1024px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

// Gallery
//
.property__gallery {
  // 1024
  @media (min-width: 1024px) {
    width: 66.66%;
    width: calc((100% / 3) * 2);
  }
}

// Description
//
.property__description {
  margin-top: 0.25rem;
  width: 100%;

  // 1024
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    flex-flow: row wrap;
  }
}

// Left
//
.property__left {
  // 1024
  width: 100%;
}

// Right
//
.property-documents {
  // 1023 <=
  margin-top: 0.25rem;
}

// Details
.property__details {
  marign-top: 0px;
  padding-top: 0.25rem;
  padding-left: 0px;
  margin-bottom: 30px;
  list-style-type: none;
  ///// Adjust Border
  border-top: 2px solid black;
}

// Detail
.property__detail {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .single-properties & {
    span:last-child {
      padding-left: 0.25rem;

      text-align: right;
    }
  }
}

// Adjacent Details
.property__detail + .property__detail {
  margin-top: 1px;
  padding-top: 1px;
  border-top: 2px solid black;
}

// Reduced
.property__reduced {
  width: 100%;
}

// Gallery Navigation
.property__gallery__nav {
  margin-top: 0.25rem;

  // 767 <=
  @media (max-width: 767px) {
    display: none !important;
  }
}

// Gallery Item
.property__gallery__item {
  outline: none;

  img {
    display: block;
    margin: auto;
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

.property__gallery__large {
  margin-bottom: 15px;
}

.js-property-gallery,
.js-property-gallery-nav {
  .slick-arrow {
    font-size: 35px;
    color: color('orange');
    z-index: 999;

    &::before {
      content: none;
    }
  }

  .slick-next {
    right: 15px;
  }

  .slick-prev {
    left: 15px;
  }
}

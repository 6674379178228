// Default typography styling.
//
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: rem(20px);
  font-weight: 500;
}

h1,
h3,
h4,
h5 {
  font-family: $colus;
}

h1,
h3,
h5 {
  color: color('green');
}

h2,
h4,
h6 {
  color: color('orange');
}

h2,
h6 {
  font-family: $paytone-one;
}

h1 {
  @include fluid-type($base-min-width, $base-max-width, 26px, 30px);
}

h2 {
  @include fluid-type($base-min-width, $base-max-width, 26px, 30px);
}

h3 {
  @include fluid-type($base-min-width, $base-max-width, 21px, 26px);
}

h4 {
  @include fluid-type($base-min-width, $base-max-width, 18px, 24px);
}

h5 {
  @include fluid-type($base-min-width, $base-max-width, 16px, 20px);
}

h6 {
  @include fluid-type($base-min-width, $base-max-width, 16px, 20px);
}

p,
li:not([class]),
address {
  @include fluid-type($base-min-width, $base-max-width, 18px, 20px);
}

b,
strong {
  font-family: $roboto-bold;
  font-weight: 500;
}

i,
em {
  font-family: $roboto-light-italic;
  font-style: normal;
}

address {
  margin-bottom: rem(halve($base-gutter));
  font-style: normal;
}

small {
  @include fluid-type($base-min-width, $base-max-width, 14px, 16px);
}

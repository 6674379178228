// Breakpoints
///
$breakpoints: (
  xs: 480px,  // Extra Small devices (landscape phones, 480px and up)
  sm: 768px,  // Small devices (tablets, 768px and up)
  md: 1024px, // Medium devices (desktops, 1024px and up)
  lg: 1200px, // Large devices (desktops, 1200px and up)
  xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.7;
$base-font-color:   #122335;
$base-min-width:    320px;
$base-max-width:    1200px;

// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

// Colors Map for @function color();
///
$colors: (
  'grey': (
    'default':      #efefef,
    'medium':       #e5e5e5,
    'shadow':       #3c352a
  ),
  'bigrigmedia': (
    'orange':       #f6921e,
    'grey':         #4d4d4d
  ),
  'green': (
    'default':      #456a34,
    'link':        #345525
  ),
  'orange': (
    'default':      #e8802c
  ),
  'beige': (
    'default':      #f7f4f1
  ),
  'blue': (
    'default':      #122335
  ),
  'yellow': (
    'default':      #f8ca24
  )
);

// Alerts Map for @function alert();
///
$alerts: (
  'success': (
    'default':  #dff0d8,
    'message':  #3c763d,
    'border':   #d6e9c6
  ),
  'info': (
    'default':  #d9edf7,
    'message':  #31708f,
    'border':   #bce8f1
  ),
  'warning': (
    'default':  #fcf8e3,
    'message':  #8a6d3b,
    'border':   #faf2cc
  ),
  'error': (
    'default':  #f2dede,
    'message':  #a94442,
    'border':   #ebcccc
  )
);

// Social Networks Map for @function social();
///
$social-networks: (
  facebook:   #3b5998,
  twitter:    #00aced,
  googleplus: #dd4b39,
  linkedin:   #007bb6,
  youtube:    #bb0000,
  instagram:  #517fa4,
  pinterest:  #cb2027,
  flickr:     #ff0084,
  tumblr:     #32506d,
  foursquare: #0072b1,
  dribble:    #ea4c89,
  vine:       #00bf8f
);

// Z-index Map for @function z();
///
$z-index: (
  debug: 1,
  behind: -1,
  demigod: 1000,
  god: 99999
);

// Fonts
//
$paytone-one: 'paytone_oneregular';
$roboto-light: 'robotolight';
$roboto-light-italic: 'robotolight_italic';
$roboto-bold: 'robotobold';
$roboto-italic: 'robotoitalic';
$colus: 'colusregular';

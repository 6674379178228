// Default image element styling.
//
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

img:not([src]) {
  visibility: hidden;
}

figure {
  margin: 0;
}

figcaption {
  font-size: rem(14px);
  font-style: normal;
}

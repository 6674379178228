// CMS Content
//
// Use these classes for the content component(s).
//
// Markup:
// <div class="cms-content">Content</div>
// <section class="cms-content">Content</button>
//
// Modifier:
// <div class="cms-content content--home">Content</div>
//
.cms-content,
.static-content {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: rem($base-gutter) rem(halve($base-gutter));

  // Medium Desktops
  @include media(md) {
    padding-top: rem(double($base-gutter));
    padding-bottom: rem(double($base-gutter));
  }

  a:not([class]) {
    color: color('green', 'link');

    @include link-states() {
      color: color('orange');
    }
  }
}

// Header
//
.main-content__header {
  position: relative;
  margin-bottom: rem($base-gutter);
  padding-bottom: rem(10px);

  h1 {
    margin-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 800px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    background-color: color('yellow');
  }
}

.testimonial {
  outline-style: none;

  cite {
    @include fluid-type($base-min-width, $base-max-width, 16px, 18px);
    font-family: $roboto-bold;
    font-style: normal;
    color: color('green');
  }
}

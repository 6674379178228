.map {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  background-color: color('beige');

  // Medium Desktops
  @include media(md) {
    flex-direction: row;
    justify-content: flex-end;
    height: 80vh;
  }

  // Extra Large Desktops
  @include media(xl) {
    height: 55vh;
  }

  #map {
    width: 100vw;
    height: 100%;

    // Medium Max
    @include media-max(md) {
      min-height: 30vh;
    }

    // Mobile Landscape
    @include media(xs) {
      min-height: 50vh;
    }
  }
}

.map__content {
  padding: rem($base-gutter);
  background-color: white;

  // Medium Desktops
  @include media(md) {
    position: absolute;
    top: 45px;
    bottom: 30px;
    right: 6%;
    max-height: 445px;
    box-shadow: 0 0 10px rgba(color('grey', 'shadow'), 0.05);
  }

  .info--name {
    margin-bottom: 0;
  }

  .info--address {
    margin-bottom: 0;
  }

  .info--phone,
  .info--email {
    @include fluid-type($base-min-width, $base-max-width, 18px, 20px);
    color: currentColor;

    @include link-states() {
      color: color('orange');
    }
  }

  .btn {
    margin-top: rem(halve($base-gutter));
  }
}

// Post Adjacent Sibling
//
.post + .post {
  margin-top: rem($base-gutter);
}

// Header
//
.post__header {
  margin-bottom: rem(halve($base-gutter));
}

// Title
//
.post__title {
  margin-bottom: 0;
}

// Header
//
// Use this class for the header component.
//
// Markup:
// <div class="header">Header</div>
// <section class="header">Header</section>
// <header class="header">Header</header>
//
.header {
  background-image: url('../../dist/images/wood.jpg');
  background-repeat: repeat;
  border-top: 6px solid color('green');

  // Medium Max
  @include media-max(md) {
    height: 100px;
  }
}

.header-container {
  position: relative;
  width: 100%;
  max-width: $base-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(halve($base-gutter));
  padding-right: rem(halve($base-gutter));

  // Medium Max
  @include media-max(md) {
    height: 100%;
  }

  // Medium Desktops
  @include media(md) {
    text-align: right;
  }
}

.header__actions {
  // Medium Max
  @include media-max(md) {
    display: none;
  }

  // Medium Desktops
  @include media(md) {
    margin-bottom: rem($base-gutter);
  }

  .info--phone {
    font-size: rem(28px);
    font-family: $paytone-one;
    color: white;
    text-shadow: 1px 1px 0 rgba(black, 0.5);

    @include link-states() {
      color: color('orange');
    }
  }

  .btn {
    // Medium Desktops
    @include media(md) {
      position: relative;
      top: neg(7px);
      margin-left: rem(halve($base-gutter));
      font-size: rem(18px);
    }
  }
}

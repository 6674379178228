// Tablepress
//
.tablepress {
  thead {
    th {
      padding: rem(5px) rem(halve($base-gutter));
      font-size: rem(20px);
      font-family: $paytone-one;
      font-weight: 500;
      color: color('green');
      background-color: transparent;

      // Medium Desktops
      @include media(md) {
        white-space: nowrap;
      }
    }
  }

  tbody {
    td {
      padding: rem(5px) rem(halve($base-gutter));
      font-size: rem(20px);
      border-top: 1px solid color('green');
    }
  }

  .row-2,
  .row-8,
  .row-14,
  .row-20 {
    td {
      font-family: $paytone-one;
      color: white;
      background-color: color('green');
    }

    td:not(:first-of-type) {
      padding: 0;
    }
  }
}

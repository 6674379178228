// Container
//
// Use these class for the container object.
//
// Markup:
// <div class="container">Container</div>
//
// Modifier:
// <div class="container container--fluid">Container</div>
//
.container {
  @include make-container;
}

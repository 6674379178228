// Footer
//
// Use this class for the footer component.
//
// Markup:
// <div class="footer">Footer</div>
// <section class="footer">Footer</section>
// <footer class="footer">Footer</footer>
//
.footer {
  padding-top: rem($base-gutter);
  padding-bottom: rem($base-gutter);
  color: white;
  background-color: color('blue');

  hr {
    margin-top: rem(halve($base-gutter));
    margin-bottom: rem(halve($base-gutter));
    background-color: rgba(white, 0.15);
  }
}

.footer-container {
  position: relative;
  width: 100%;
  max-width: $base-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: rem(halve($base-gutter));
  padding-right: rem(halve($base-gutter));
}

.footer-logos {
  margin-bottom: rem(halve($base-gutter));
}

.footer-logo + .footer-logo {
  margin-left: rem(10px);
}

.footer__copyright {
  margin-top: 0;
  margin-bottom: 0;
  font-size: rem(12px);
  text-transform: uppercase;

  a {
    color: currentColor;

    @include link-states() {
      color: color('orange');
    }
  }
}

// Featured Row
//
.featured-row {
  display: flex;
  flex-flow: column nowrap;

  // Medium Desktops
  @include media(md) {
    flex-flow: row wrap;
  }
}

// Text & Image - Split Column Layout
//
.featured-row__text,
.featured-row__image {
  flex: 1 1 100%;

  // Medium Desktops
  @include media(md) {
    flex-basis: 50%;
  }
}

// Text - Split Column Layout
//
.featured-row__text {
  order: 1;
  padding: rem($base-gutter) rem(halve($base-gutter));

  // Large Desktops
  @include media(lg) {
    padding: rem(double($base-gutter)) rem($base-gutter);
  }

  .featured-row--invert & {
    order: 2;
  }
}

// Image - Split Column Layout
//
.featured-row__image {
  order: 2;
  min-height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .featured-row--invert & {
    order: 1;
  }
}

// Content - Split Column Layout
//
.featured-row__content {
  // Medium Max
  @include media-max(md) {
    text-align: center;
  }

  // Large Desktops
  @include media(lg) {
    width: 100%;
    max-width: 555px;
    margin-left: auto;
    margin-right: 0;

    .featured-row--invert & {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

// Column - Columns Layout
//
.featured-row__column {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
  padding: rem($base-gutter) rem(halve($base-gutter));
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  // Medium Max
  @include media-max(md) {
    text-align: center;
  }

  // Medium Desktops
  @include media(md) {
    flex-basis: 33.3333333333%;
    padding: rem(double($base-gutter));
  }

  h1 {
    color: currentColor;
  }

  p:last-of-type {
    margin-bottom: auto;
  }
}

// Inner
//
.featured-row__inner {
  height: 100%;
}

// Weather
//
.weather {
  display: flex;
  flex-flow: row nowrap;
  margin-left: neg(rem(halve($base-gutter)));
  margin-right: neg(rem(halve($base-gutter)));
}

// Weather Day
//
.weather__day {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 50%;
  padding-left: rem(halve($base-gutter));
  padding-right: rem(halve($base-gutter));

  // Medium Max
  @include media-max(md) {
    text-align: center;
  }

  // Medium Desktops
  @include media(md) {
    flex-direction: row;
    flex-basis: 33.3333333333%;
  }
}

.weather__day:not(:nth-child(1)):not(:nth-child(2)) {
  // Landscape
  @media (min-width: 320px) and (max-width: 736px) {
    display: none;
  }
}

.weather__icon,
.weather__icon {
  flex: 1 1 100%;
}

// Weather Info
//
.weather__info {
  order: 2;
  color: white;

  // Medium Desktops
  @include media(md) {
    order: 1;
    flex-basis: 66.6666666667%;
  }

  .wu-day-title {
    @include fluid-type($base-min-width, $base-max-width, 21px, 26px);
    font-family: $colus;
    text-transform: uppercase;
  }

  .wu-cond-pop {
    position: relative;

    &:before {
      content: 'Chance of Precip. ';
    }
  }
}

// Weather Icon
//
.weather__icon {
  order: 1;

  // Medium Desktops
  @include media(md) {
    order: 2;
    flex-basis: 33.3333333333%;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

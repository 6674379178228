// Hero
//
// Use this class for the hero component.
//
// Markup:
// <div class="hero">Hero</div>
// <section class="hero">Hero</section>
//
.hero {
  border-bottom: 6px solid color('green');
  overflow: hidden;
}

// Hero Item
//
.hero__item {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .page &,
  .archive &,
  .blog &,
  .single & {
    height: 60vh;

    // Landscape
    @include media(xs) {
      height: 100vh;
    }

    // Small Tablets
    @include media(sm) {
      height: 40vh;
    }

    // Medium Desktops
    @include media(md) {
      height: 60vh;
    }

    // Extra Large Desktops
    @include media(xl) {
      height: 50vh;
    }
  }

  .page-template-template-home & {
    height: 60vh;

    // iPhone SE
    @media (min-width: 319px) and (max-width: 320px) {
      height: 65vh;
    }

    // Landscape
    @include media(xs) {
      height: 100vh;
    }

    // Small Tablets
    @include media(sm) {
      height: 40vh;
    }

    // Medium Desktops
    @include media(md) {
      height: 60vh;
    }

    // Extra Large Desktops
    @include media(xl) {
      height: 50vh;
    }
  }
}

// Hero Content
//
.hero__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;

  // Medium Max
  @include media-max(md) {
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));
  }

  h1 {
    @include fluid-type($base-min-width, $base-max-width, 30px, 48px);
    line-height: 1.2;
    color: white;
    text-shadow: 0 0 5px black;

    // Medium Max
    @include media-max(md) {
      margin-top: rem(double($base-gutter));
    }
  }
}

// Navigation
//
// Use this class for the nav component.
//
// Markup:
// <nav class="nav">Navigation</nav>
// <ul class="nav">Navigation</ul>
//
.nav--mobile {
  //padding: rem(5px) rem(halve($base-gutter));
  padding-top: rem(5px);
  padding-bottom: rem(5px);
  text-align: center;
  background-color: color('green');
  @include clearfix;

  // Medium Desktops
  @include media(md) {
    display: none;
  }

  .nav__dropdown {
    .nav__item + .nav__item {
      padding-top: 0;
    }
  }
}

.nav--header {
  // Medium Max
  @include media-max(md) {
    display: none;
  }
}

.nav--pagination {
  margin-top: rem($base-gutter);

  .current {
    display: block;
    padding: rem(5px) rem(10px);
    font-size: rem(18px);
    font-family: $paytone-one;
    color: white;
    text-shadow: 1px 1px 0 rgba(black, 0.5);
    text-transform: uppercase;
    background-color: color('orange');
  }
}

// Navigation List
//
.nav__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;

  .nav--mobile & {
    display: none;
    margin-top: rem(double($base-gutter));
    clear: both;

    > .nav__item + .nav__item {
      border-top: 1px solid color('green', 'link');
    }

    .nav-is-visible & {
      display: block;
    }
  }
}

// Navigation Item
//
.nav__item {
  .nav--header &,
  .nav--footer &,
  .nav--pagination & {
    // Medium Desktops
    @include media(md) {
      display: inline-block;
      vertical-align: top;
    }
  }

  .nav--mobile &,
  .nav--header & {
    position: relative;
  }

  .nav--header & {
    // Medium Desktops
    @include media(md) {
      padding-bottom: rem(halve($base-gutter));
    }
  }

  a {
    font-size: rem(18px);
    font-family: $paytone-one;
    color: white;
    text-shadow: 1px 1px 0 rgba(black, 0.5);
    text-transform: uppercase;

    .nav--header & {
      // Medium Desktops
      @include media(md) {
        font-size: rem(14px);
      }

      // Large Desktops
      @include media(lg) {
        font-size: rem(18px);
      }
    }

    .nav--pagination & {
      display: block;
      padding: rem(5px) rem(10px);
      background-color: color('green');

      @include link-states() {
        background-color: color('orange');
      }
    }

    @include link-states() {
      .nav--header &,
      .nav--footer & {
        color: color('orange');
      }
    }
  }
}

// Navigation Item Hover
//
.nav__item:hover {
  // Medium Desktops
  @include media(md) {
    .nav__dropdown {
      display: block;
    }
  }
}

// Navigation Item Adjacent Sibling
//
.nav__item + .nav__item {
  .nav--header &,
  .nav--footer &,
  .nav--pagination & {
    // Medium Desktops
    @include media(md) {
      margin-left: rem(halve($base-gutter));
    }
  }

  .nav--header & {
    // Large Desktops
    @include media(lg) {
      margin-left: rem(10px);
    }
  }

  .nav--mobile & {
    // Medium Max
    @include media-max(md) {
      margin-top: rem(halve($base-gutter));
      padding-top: rem(halve($base-gutter));
    }
  }
}

// Navigation Phone
//
.nav-phone {
  float: left;
  margin-left: rem(halve($base-gutter));
  font-size: rem(25px);
  color: white;
}

// Navigation Toggle
//
.nav-toggle {
  float: right;
  position: relative;
  top: 5px;
  right: 0;
  z-index: z('god');
  width: 32px;
  height: 32px;
  margin-right: rem(halve($base-gutter));
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  outline: none;
  clear: right;

  // Medium Desktops
  @include media(md) {
    display: none;
  }
}

// Toggle Line
//
.nav-toggle__line {
  display: block;
  position: relative;
  width: 32px;
  height: 3px;
  background-color: white;
  border-radius: 3px;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-duration: 0.32s;

  // Medium Desktops
  @include media(md) {
    margin-left: auto;
    margin-right: auto;
  }

  .nav-is-visible & {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: inherit;
    border-radius: 3px;
  }

  &:before {
    top: neg(8px);
    transition: top 0.2s ease-in .25s, opacity .1s ease-in;

    .nav-is-visible & {
      top: 0;
      opacity: 0;
      transition: top 0.2s ease-out, opacity .1s ease-out .12s;
    }
  }

  &:after {
    bottom: neg(8px);
    transition: bottom 0.2s ease-in .25s, transform 0.32s cubic-bezier(.55, .055, .675, .19);

    .nav-is-visible & {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.2s ease-out, transform 0.32s cubic-bezier(.215, .61, .355, 1) .12s;
    }
  }
}

// Dropdown
//
.nav__dropdown {
  display: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;

  // Medium Max Desktops
  @include media-max(md) {
    margin-top: rem(halve($base-gutter));
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(40px));
  }

  // Medium Desktops
  @include media(md) {
    position: absolute;
    top: 100%;
    left: neg(15px);
    z-index: z('demigod');
    min-width: 200px;
    padding: rem(halve($base-gutter)) rem(halve($base-gutter));
    text-align: left;
    background-color: color('green');
  }

  .nav__item {
    display: block;
    padding-bottom: 0;

    a {
      font-size: rem(16px);
    }
  }

  .nav__item + .nav__item {
    // Medium Desktops
    @include media(md) {
      margin-top: rem(5px);
      margin-left: 0;
    }
  }

  .is-expanded & {
    display: block;
  }
}

// Dropdown Toggle
//
.dropdown-toggle {
  // Medium Max Desktops
  @include media-max(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 15px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: 0;
    appearance: none;
    cursor: pointer;
  }

  &:after {
    // Medium Max Desktops
    @include media-max(md) {
      content: '\f078';
      font-size: rem(20px);
      font-family: 'Font Awesome 5 Free';
      color: white;

      .is-expanded & {
        content: '\f077';
      }
    }
  }

  // Medium Desktops
  @include media(md) {
    display: none;
  }
}

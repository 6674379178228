// Default column styling.
//
.col {
  float: left;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: rem(halve($base-gutter));
  padding-left: rem(halve($base-gutter));
}

// Default inner column.
//
.col__inner {
  width: 100%;
}

// Create generic columns
//
@for $i from 1 through $base-columns {
  .col--#{$i} {
    width: percentage($i / $base-columns);
  }
}

// Create breakpoint based columns
//
@each $breakpoint in map-keys($breakpoints) {
  @include media($breakpoint) {
    @for $i from 1 through $base-columns {
      .col--#{$i}\@#{$breakpoint} {
        width: percentage($i / $base-columns);
      }
    }
  }
}

// Create column offsets
//
@if $column-offsets {
  // Create generic column offsets
  //
  @for $i from 1 through $base-columns {
    .col--offset-#{$i} {
      margin-left: percentage($i / $base-columns);
    }
  }

  // Create breakpoint based column offsets
  //
  @each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
      @for $i from 1 through $base-columns {
        .col--offset-#{$i}\@#{$breakpoint} {
          margin-left: percentage($i / $base-columns);
        }
      }
    }
  }
}

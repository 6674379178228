// Button
//
// Use these classes for the button component.
//
// Markup:
// <a class="btn">Click Me</div>
// <button class="btn">Click Me</button>
//
// Modifier:
// <div class="btn btn--red">Click Me</div>
//
.btn {
  display: inline-block;
  padding: rem(5px) rem(20px);
  font-family: $paytone-one;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  @include fluid-type($base-min-width, $base-max-width, 20px, 22px);
}

// Orange
//
.btn--orange {
  background-color: color('orange');

  @include link-states() {
    background-color: color('yellow');
  }
}

// Green
//
.btn--green {
  background-color: color('green');

  @include link-states() {
    background-color: color('orange');
  }
}

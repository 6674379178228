// Social Link
//
.social-link {
  font-size: rem(34px);
  color: white;
  text-decoration: none;

  @include link-states() {
    color: color('orange');
  }

  + .social-link {
    margin-left: rem(10px);
  }
}

// Generic text transforms
//
// Use these classes modify text appearance.
//
// Markup:
// <p class="ttn">Text</p>
//
.ttl {
  text-transform: lowercase;
}

.ttu {
  text-transform: uppercase;
}

.ttn {
  text-transform: none;
}

// Generic text aligns
//
// Use these classes to modify text alignment.
//
// Markup:
// <p class="tal">Text</p>
//
.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.tac {
  text-align: center;
}

// Breakpoint based text aligns
//
// Use theses classes to modify text alignment based on breakpoint(s).
//
// Markup:
// <p class="text-left@md">Text</p>
//
@each $breakpoint in map-keys($breakpoints) {
  @include media($breakpoint) {
    .tal\@#{$breakpoint} {
      text-align: left;
    }
  }
}

@each $breakpoint in map-keys($breakpoints) {
  @include media($breakpoint) {
    .tar\@#{$breakpoint} {
      text-align: right;
    }
  }
}

@each $breakpoint in map-keys($breakpoints) {
  @include media($breakpoint) {
    .tac\@#{$breakpoint} {
      text-align: center;
    }
  }
}

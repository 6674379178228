// Font Utility Classes
//
// Use these classes to change font family, font weight, color, etc.
//

.cg {
  color: color('green');
}

.co {
  color: color('orange');
}

.cy {
  color: color('yellow');
}

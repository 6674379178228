// Fonts
//
// Example:
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
  font-family: 'paytone_oneregular';
  src: url('../../dist/fonts/paytoneone-webfont.woff2') format('woff2'),
       url('../../dist/fonts/paytoneone-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoblack';
  src: url('../../dist/fonts/roboto-black-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'robotoblack_italic';
  src: url('../../dist/fonts/roboto-blackitalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('../../dist/fonts/roboto-bold-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold_italic';
  src: url('../../dist/fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoitalic';
  src: url('../../dist/fonts/roboto-italic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('../../dist/fonts/roboto-light-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight_italic';
  src: url('../../dist/fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('../../dist/fonts/roboto-medium-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium_italic';
  src: url('../../dist/fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('../../dist/fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotothin';
  src: url('../../dist/fonts/roboto-thin-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotothin_italic';
  src: url('../../dist/fonts/roboto-thinitalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/roboto-thinitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedbold';
  src: url('../../dist/fonts/robotocondensed-bold-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedbold_italic';
  src: url('../../dist/fonts/robotocondensed-bolditalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condenseditalic';
  src: url('../../dist/fonts/robotocondensed-italic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedlight';
  src: url('../../dist/fonts/robotocondensed-light-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedlight_italic';
  src: url('../../dist/fonts/robotocondensed-lightitalic-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedregular';
  src: url('../../dist/fonts/robotocondensed-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/robotocondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'colusregular';
  src: url('../../dist/fonts/colus-regular-webfont.woff2') format('woff2'),
       url('../../dist/fonts/colus-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

///
/// Returns unitless number.
///
/// @param {size} $size
///
@function strip-unit($size) {
  @return $size / ($size * 0 + 1);
}

///
/// Returns fluid typography.
///
/// @param {min-vw} $min-vw {max-vw} $max-vw {min-font-size} $min-font-size {max-font-size} $max-font-size
/// @link https://css-tricks.com/snippets/css/fluid-typography/
///
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}


// Section
//
// Use this class for the section component.
//
// Markup:
// <div class="section">Content</div>
// <section class="Section">Content</button>
//
// Modifier:
// <div class="section section--intro">Content</div>
//
.section {
  &.has-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// White
//
.section--white {
  background-color: white;
}

// Green
//
.section--green {
  color: white;
  background-color: color('green');

  h1 {
    color: currentColor;
  }
}

// Beige
//
.section--beige {
  background-color: color('beige');
}

// Testimonials
//
.section--testimonials {
  border-top: 6px solid color('green');
}

// Weather
//
.section--weather {
  background-image: url('../../dist/images/wood.jpg');
  background-repeat: repeat;
}

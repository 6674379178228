// Gravity Forms
//
.gform_button {
  font-size: rem(20px) !important;

  // Medium Desktops
  @include media(md) {
    font-size: rem(22px) !important;
  }
}

///
// jQuery UI
///
.ui-datepicker-title {
  overflow: hidden;
}

.ui-datepicker-month,
.ui-datepicker-year {
  width: 50%;
  margin-top: 0 !important;
  color: $base-font-color !important;
}

.ui-datepicker-month {
  float: left;
}

.ui-datepicker-year {
  float: right;
}

.ui-datepicker {
  td {
      a {
          font-weight: 500 !important;
          color: $base-font-color !important;
          text-shadow: none !important;
      }
  }
}
